<template>
  <div class="h-10">
    <h1 class="font-bold text-lg capitalize">Caja</h1>
  </div>
  <div class="box">
    <div class="p-8">
      <div class="flex justify-between gap-2">
        <div class="flex gap-2 items-center">
          <font-awesome-icon icon="clipboard-list" style="width: 25px; height: 25px;color: #1C3FAA"/>
          <span class="text-base font-bold" style="color: #1C3FAA">Legalizar Egreso - Factura</span>
        </div>
        <back-button/>
      </div>
      <div class="grid grid-cols-4 gap-4 mt-8">
        <div class="flex flex-col">
          <span>Clase Articulo / Servicio</span>
          <Dropdown v-model="selectedType" :options="types" optionLabel="name" optionValue="value" placeholder="Seleccione una opcion" />
        </div>
        <div class="flex flex-col">
          <span>Proveedor</span>
          <InputText type="text" v-model="header.proveedor" />
        </div>
        <div class="flex flex-col">
          <span>N° Ref Acreedor</span>
          <InputText type="text" v-model="header.noRefAcreedor" />
        </div>
        <div class="flex flex-col">
          <span>Tipo de factura</span>
          <Dropdown v-model="selectedInvoicesTypes" :options="storeInvoicesTypes" optionLabel="name" optionValue="id" placeholder="Seleccione una opcion" />
        </div>
      </div>
      <DataTable :value="model" responsiveLayout="scroll" class="mt-6 p-datatable-sm">
        <template #empty>
          <span>No hay datos para mostrar</span>
        </template>
        <Column field="descripcion" header="Descripcion">
          <template #body="{ data, index }">
            <InputText type="text" v-model="data.descripcion" placeholder="Descripcion" />
            <span class="text-red-500">{{ errors[`[${index}].descripcion`] }}</span>
          </template>
        </Column>
        <Column field="cuentaMayor" header="Cuenta Mayor">
          <template #body="{data, index}">
            <InputText type="text" v-model="data.cuentaMayor" placeholder="Descripcion" />
            <span class="text-red-500">{{ errors[`[${index}].cuentaMayor`] }}</span>
          </template>
        </Column>
        <Column field="normaReparto" header="Norma de reparto">
          <template #body="{data, index}">
            <InputText type="text" v-model="data.normaReparto" placeholder="Norma de reparto" />
            <span class="text-red-500">{{ errors[`[${index}].normaReparto`] }}</span>
          </template>
        </Column>
        <Column field="cantidad" header="Cantidad">
          <template #body="{data, index}">
            <InputNumber v-model="data.cantidad" placeholder="Cantidad" />
            <span class="text-red-500">{{ errors[`[${index}].cantidad`] }}</span>
          </template>
        </Column>
        <Column field="precioUnid" header="Precio Unid">
          <template #body="{data, index}">
            <InputNumber v-model="data.precioUnid" placeholder="Precio Unid" />
            <span class="text-red-500">{{ errors[`[${index}].precioUnid`] }}</span>
          </template>
        </Column>
        <Column field="decuento" header="% Desc.">
          <template #body="{data, index}">
            <InputNumber v-model="data.decuento" placeholder="% Desc." />
            <span class="text-red-500">{{ errors[`[${index}].decuento`] }}</span>
          </template>
        </Column>
        <Column field="indicador" header="Indicador de Impuestos">
          <template #body="{data, index}">
            <InputText type="text" v-model="data.indicador" placeholder="Indicador de Impuestos" />
            <span class="text-red-500">{{ errors[`[${index}].indicador`] }}</span>
          </template>
        </Column>
        <Column field="sujetoRetencion" header="Sujeto a Retencion">
          <template #body="{data, index}">
            <InputText type="text" v-model="data.sujetoRetencion" placeholder="Sujeto a Retencion" />
            <span class="text-red-500">{{ errors[`[${index}].sujetoRetencion`] }}</span>
          </template>
        </Column>
        <Column field="total" header="Total">
          <template #body="{data, index}">
            <InputNumber v-model="data.total" placeholder="Total" />
            <span class="text-red-500">{{ errors[`[${index}].total`] }}</span>
          </template>
        </Column>
        <template #footer>
          <div class="flex flex-row-reverse mt-2 gap-2">
            <Button icon="pi pi-plus" class="p-button-success" @click="add" />
            <Button icon="pi pi-minus" class="p-button-danger" @click="remove" />
          </div>
        </template>
      </DataTable>
      <div class="mt-8 p-4" style="background-color: #F1F5F8">
        <div class="flex gap-4">
          <div class="flex flex-col w-1/2">
            <span>Comentarios</span>
            <Textarea v-model="header.comentarios" :autoResize="true" rows="5" cols="30" />
          </div>
          <div class="flex flex-col w-1/2">
            <span>Concepto</span>
            <Textarea v-model="header.concepto" :autoResize="true" rows="5" cols="30" />
          </div>
        </div>
        <div class="flex flex-col mt-4">
          <span>Total factura sin descuento: $0,00</span>
          <span>Descuento % <InputNumber v-model="header.total" placeholder="Descuento" class="p-inputtext-xs" />$0,00</span>
          <span>Total factura: $0,00</span>
        </div>
      </div>
      <div class="flex flex-col mt-4">
        <span>Adjuntar Factura</span>
        <FileUpload mode="basic" name="demo[]" accept="application/pdf" :maxFileSize="1000000" @upload="onUpload" chooseLabel="Examinar" />
      </div>
      <div class="flex flex-row-reverse mt-4 gap-4">
        <Button label="Cancelar" class="p-button-warning" @click="$router.go(-1)" />
        <Button label="Ingresar factura" @click="send" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { messageWarning } from '@/libs/mensajes'

export default {
  name: 'legalizeExpenseAdd',
  setup () {
    const store = useStore()
    const types = [
      { name: 'Articulo', value: 1 },
      { name: 'Servicio', value: 2 }
    ]
    const data = ref([
      {
        descripcion: '',
        cuentaMayor: '',
        normaReparto: null,
        cantidad: null,
        precioUnid: null,
        decuento: null,
        indicador: null,
        sujetoRetencion: null,
        total: null
      }
    ])
    const selectedType = ref(null)
    const selectedInvoicesTypes = ref(null)
    const storeInvoicesTypes = computed(() => store.getters['storePettyCash/getInvoicesTypes'])

    const header = ref({
      proveedor: '',
      noRefAcreedor: '',
      comentarios: '',
      concepto: '',
      total: 0
    })

    const schema = yup
      .array()
      .of(
        yup.object().shape({
          descripcion: yup.string().nullable().required('El campo es requerido').label(''),
          cuentaMayor: yup.string().nullable().required('El campo es requerido').label(''),
          normaReparto: yup.string().nullable().required('El campo es requerido').label(''),
          cantidad: yup.number().nullable().required('El campo es requerido').label(''),
          precioUnid: yup.number().nullable().required('El campo es requerido').label(''),
          decuento: yup.number().nullable().required('El campo es requerido').label(''),
          indicador: yup.string().nullable().required('El campo es requerido').label(''),
          sujetoRetencion: yup.string().nullable().required('El campo es requerido').label(''),
          total: yup.number().nullable().required('El campo es requerido').label('')
        })
      )

    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema,
      initialValues: data
    })

    const send = handleSubmit((values) => {
      if (!values) return console.error('Error => validation')
      if (!selectedType.value) messageWarning('Clase requerido')
      if (!selectedInvoicesTypes.value) messageWarning('Tipo factura requerido')
      if (!header.value.proveedor) messageWarning('Proveedor requerido')
      if (!header.value.noRefAcreedor) messageWarning('N°RefAcreedor requerido')
      if (!header.value.comentarios) messageWarning('comentarios requerido')
      if (!header.value.concepto) messageWarning('Concepto requerido')
      if (!header.value.total) messageWarning('Total requerido')

      console.log(values)
    })

    const add = () => {
      model.push({
        descripcion: null,
        cuentaMayor: null,
        normaReparto: null,
        cantidad: null,
        precioUnid: null,
        decuento: null,
        indicador: null,
        sujetoRetencion: null,
        total: null
      })
    }

    const remove = () => {
      if (model.length > 1) {
        model.splice(-1, 1)
      } else {
        messageWarning('Debe exirtir al menos un registro')
      }
    }

    const onUpload = (event) => {
      console.log(event)
    }

    onMounted(async () => {
      await store.dispatch('storePettyCash/storeInvoicesTypes')
      console.log(storeInvoicesTypes.value)
    })

    return {
      types,
      selectedType,
      storeInvoicesTypes,
      selectedInvoicesTypes,
      model,
      errors,
      send,
      data,
      onUpload,
      add,
      remove,
      header
    }
  }
}
</script>

<style scoped>

</style>
